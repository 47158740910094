export default {
  cardBorderRadius: '1rem',
  expandButtonWidth: '18rem',
  expandButtonMargin: '0 auto',
  expandButtonBorderRadius: '1000px',
  expandButtonBorder: '2px solid #000',
  h2FontSize: '1.5rem',
  paddingBottom: '3rem',
  height: '12rem',
  borderRadius: '5px',
  border: '1px solid var(--transparent-black-09)',
  postTitleColor: 'var(--mbc)',
  textAlign: 'left',
  postDescriptionHeight: '8rm',
  buttonBackgroundColor: 'var(--transparent)',
  buttonColor: 'var(--dark-teal)',
  bottom: '1.5rem',
  transform: 'scaleX(0.75)',
  postTitlePadding: '0 1.5rem 0',
  postDescriptionPadding: '0 1.5rem 1.5rem',
  titleMarginBottom: '.5rem',
  postsMargin: '0',
  mockLinkMainColor: 'var(--mbc)',
  linkBG: 'var(--lightest-grey)',
};
