export default {
  flexDirection: 'row',
  tabletFlexDirection: 'column',
  mobileFlexDirection: 'column',
  imageWidth: '7.5rem',
  imageHeight: '7.5rem',
  imageWrapper: '0 auto 2rem',
  mobileImageMargin: '0 auto',
  stepFontWeight: '400',
  stepMargin: '0 auto 3rem',
  stepByStepMaxWidth: '80%',
  pColor: 'var(--black)',
  marginStepImage: '0 auto 1rem',
  textWrapperWidth: '90%',
  textWrapperLineHeight: '2',
  imageSize: '5rem',
};
