export default {
  authorBackground: 'white',
  childWidth: 'unset',
  lexAlign: 'center',
  marginLeft: '-1rem',
  size: '4em',
  alignment: 'left',
  linkColor: 'var(--sambla-new-red)',
  clipPath: 'circle(35% at 50% 50%)',
  backgroundContainerDisplay: 'block',
};
