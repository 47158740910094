export default {
  questionColor: 'black',
  faqIconColor: '#FF3926',
  borderRadius: '3px',
  backgroundColorFAQ: 'var(--white)',
  margin: '1.5rem 0',
  boxShadow: '0 1px 3px rgba(0, 0, 0, .25)',
  questionPadding: '1rem',
  questionJustification: 'space-between',
  answerPadding: '0.5rem 1rem 1rem',
  bulletColor: '#000',
  order: '1',
  FAQAnswerMargin: '0',
  fontSize: '1.5rem',
  letterSpacing: 'var(--has-no-letter-spacing)',
  expandButtonBorderRadius: '28px',
  borderWidth: '2px',
  borderColor: 'var(--black)',
  expandButtonMargin: 'auto',
  paddingTop: '-2rem',
  titleSizeDownLaptop: '1.6rem',
  originalSvgTransForm: '-90deg',
  buttonBackground: 'var(--transparent)',
  borderStyle: 'solid',
  svgLeft: '0',
  svgHeight: '1.5rem',
  svgColor: 'var(--mbc)',
  svgFontSize: '1rem',
  svgMarginRight: '1rem',
  svgTransform: 'rotate(-90deg)',
  svgTransformOrigin: 'center center',
  svgTransition: '0.2s ease 0s',
  svgTransformOpen: 'rotate(0deg)',
  svgOrder: '1',
  answerFontSize: '1rem',
};
