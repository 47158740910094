export default {
  contentWrapperBgOverlay: 'linear-gradient(0deg, #570014 15%, rgba(29, 39, 49, 0.00) 15%)',
  mobileTitleColor: 'var(--white)',
  buttonRadius: '99999px',
  secondaryButtonBorder: '1px solid var(--white)',
  primaryButtonBg: '#008539',
  buttonTextColor: 'var(--white)',
  secondaryButtonMobileBg: 'transparent',
  trustBoxBg: '#FFF',
};
