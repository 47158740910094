export default {
  mobileMenuBg: 'var(--sambla-red)',
  boxShadow: '2px 4px 10px rgba(0,0,0,.2);',
  mobileMenuButtonColor: 'var(--white)',
  textBgColor: 'var(--white)',
  buttonSvgWidthHeight: '2rem',
  backgroundColor: 'var(--sambla-red)',
  linkColor: 'var(--white)',
  fontFamily: 'var(--fontFamily)',
  overlayBackgroundColor: 'var(--sambla-red)',
  activeLinkColor: 'var(--headerLinkActiveColor)',
  loginButtonBackgroundColor: 'var(--mpc)',
  loginButtonTextColor: 'var(--white)',
  loginButtonBorderRadius: '30px',
};
